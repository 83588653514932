body {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: #f5f5f5 !important;
  background: transparent;
  font-family: 'Power Grotesk', sans-serif;
}


.App-container {
  max-width: 1200px; /* Set the maximum width for the container */
  width: "70%";
  margin: 0 auto; /* Center the container horizontally */
  text-align: center; /* Center the content inside the container */
  background-color: #f5f5f5;
}

.App {
  max-width: 100%; /* Set the maximum width for the App div */
  margin: 0 auto; /* Center the App div horizontally */
  background-color: #f5f5f5;
}

@media (max-width: 601px) {
  .App-container {
    width: "100%";
  }
}


.slide-up {
  animation: slideUpAnimation 0.5s ease forwards;
  background-color: #f5f5f5;
}

@keyframes slideUpAnimation {
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(0);
  }
}


/* FAZLAAAAAAAA */

.slide-left {
  animation: slideLeftAnimation 0.5s ease forwards;
}

.slide-right {
  animation: slideRightAnimation 0.5s ease forwards;
}


@keyframes slideLeftAnimation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideRightAnimation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}


