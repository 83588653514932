


.navbar_all{
  background-color:#7a7a948e;
}



.mabsan-logo {
  width: 140%;
  transition: background-color 0.3s, color 0.3s, width 0.3s;
}

.mabsan-logo:hover {
  background-color: #7a7a941e;
  border-radius: 10px;
  width: 145%;
}


.buttons {
    background: transparent;
    border: none;
    margin: 0 auto 0;
    display: block;
    font-size: 130%;
    outline: none;
    position: relative;
    transition: all .3s;
    padding-bottom: 0px;
    color: white;
}

.button_container{
  transition: all .3s;
}

.urunler_button {
  transition: all .3s;
}

  /* Styles for the dropdown */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown_content {
    position: absolute;
    background-color: transparent;
    min-width: 160px;
    z-index: 1;
    transition: all .3s;
    height: 0%;
    overflow: hidden;
  }

  .dropdown:hover .dropdown_content {
    height: 500%;

  }

  /* Adjust dropdown item styles as needed */
  .dropdown_item {
    background-color: transparent;
    color:white;
    border: none;
    text-align: left;
    cursor: pointer;
    padding-left: 15px;
    padding-top: 5px;
    width: 200px;
    font-family: 'Gotham', sans-serif;
  }

  .dropdown_item::before {
    content: '\2022'; /* Unicode character for bullet point */
    color: #278cbf; /* Color of the bullet point */
    position: absolute;
    left: 1px; /* Adjust the positioning of the bullet point */
  }

  .dropdown_item:hover {
    background-color: #278cbf;
    border-radius: 10%;
  }



.buttons:before {
    content: '';
    border-bottom: 3px solid #278cbf;
    padding: 0 25px;
    position: absolute;
    bottom: 0;
    left: 50;
    right: 50;
    margin: 0 auto;
    width: 10%;
    transition: 0.3s;
}


.buttons:hover:before {
    width: 100%;
    border-bottom: 5px solid #278cbf;

}

.buttons:hover {
  font-size: 140%;
}

.buttons:active {
    background: black;
    color: white;
    transition: none;
}


.buttontrs {
  background: transparent;
  border: none;
  margin: 0 auto 0;
  display: block;
  font-size: 130%;
  outline: none;
  position: relative;
  transition: 0.3s;
  padding-bottom: 0px;
  color: white;
  transition: background-color 0.2s, color 0.2s;
}

.buttontrs:hover {
  background-color: rgba(128, 128, 128, 0.212);
  border-radius: 10px;
  padding: 0;
}

.buttontrs:active {
  background: black;
  color: white;
  transition: none;
}


.menu_button{
  width: 100%;
}


.underline_0 {
  display: block;
  /* Add the additional classes here */
}



.underline_1 {
  display: block;
  display: flex;
  align-items: center;
  
}

.underline_2 {
  display: none;
  height: 50px;
}

.bayrak{
  width: 50%;
}
  

  
@media (max-width: 601px) {
  .underline_0{
    display: none;
  }
  .underline_1{
    display: none;
  }
  .underline_2{
    display: block;
  }

  .dropdown {
    width: 100%;
  }
  
  .dropdown_content{
    transform: translateX(55%) translateY(-20%);
    
  }

  .dropdown_item{
    font-size: 15px;
    width: 170px;
  }

  .buttons:hover{
    background-color: #7a7a941e;
    border-radius: 10%;
  }

  .mabsan-logo {
  width: 100%;
  transition: background-color 0.3s, color 0.3s, width 0.3s;
}

.mabsan-logo:hover {
  background-color: #7a7a941e;
  border-radius: 10px;
  width: 105%;
}


}

