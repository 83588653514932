/*--------------------------------------------------*/
.responsiveBodySlider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
}

.gridContainer {
  position: relative;
  width: 100px;
  height: 100px;
  transform-style: preserve-3d;
  animation: gridContaineranimate 30s linear infinite;
}

@keyframes gridContaineranimate {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.spanSlider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 15deg)) translateZ(400px);
}


.imgSliderResponsive {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
