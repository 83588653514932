
.belge-container {
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  height: 100%;
}


.belge_kart {
  height: 400px;
  border-radius: 30px 30px 0 0; /* Top-left and top-right corners have radius, bottom corners have no radius */
  transition: all .3s;

  }


  .belge_kart:hover {
    box-shadow: 0 0 20px 5px #C12C06;  /* Add a red shadow to the border on hover */
  }

.belge_card {
  display: flex;
  transition: transform 0.5s ease-in-out;    
  width: 5500%;
  align-items: center;
}
  

.belge_front{
  border-radius: 30px 0 30px 0 ; /* Top left and bottom right corners have border radius */
  height: 100% ;
  width: 1110px;
  transition: height 0.3s ease; /* Add transition for height property */
  margin-left: 10px;
  margin-top: 10px;
}

.belge_title {
  font-size: 30px;
  width: 250px;
  text-align: center;
  text-decoration: underline; /* Underline the text */
  transition: all .3s;
}


.belge_previous_button, .belge_next_button {
  width: 50px;
  height: 50px;
  background-color: #cccccc63;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease; /* Add transition for smooth animation */
}

.belge_previous_button::before, .belge_next_button::after {
  content: "\2190"; /* Unicode for left arrow */
  font-size: 30px;
}

.belge_next_button::after {
  content: "\2192"; /* Unicode for right arrow */
}

/* Additional styles */
.belge_previous_button:hover, .belge_next_button:hover {
  background-color: #999999de;

}



/* Reset hover styles when button is clicked */
.belge_previous_button:active:hover, .belge_next_button:active:hover {
  background-color: #cccccc63; /* Reset background color */

}

.belge_pagination_button{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease; /* Add transition for smooth animation */
  border: 3px solid grey; /* Add grey border */
}

.belge_pagination_button.active{
  background-color: grey;

}

.belge_pagination_button:hover{
  background-color: rgb(225, 225, 225);
  
}

.belge_button_pack{
  width: 280px;

}

.belge_title:hover{
  font-size: 70%;
}

.button_frame{
  height: 80px;
}

.surdurulebilirlik_download_button {
  width: 100%;
  background-color: #C12C06; 
  height: 80px;
  font-family: 'Power Grotesk', sans-serif;
}

.surdurulebilirlik_download_button:hover{
  background-color: rgb(126, 102, 102);
}

@media (max-width: 601px) {


  .belge_front{
    width: 390px;
    height: 100% ;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    

  }
 
  .belge_kart {
    height: 100%;
    border-radius: 20px 20px 0 0; /* Top-left and top-right corners have radius, bottom corners have no radius */

    }

    .belge_title{
      font-size: 20px;
  }


  .button_frame{
    height: 60px;
  }

  .surdurulebilirlik_download_button{
    height: 60px;
    width: 100%;
  }

  .belge_button_pack{
    width: 230px;

  }


  .belge_previous_button, .belge_next_button, .belge_pagination_button {
    width: 50px;
    height: 50px;

  }

}


