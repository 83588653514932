.baslik {
  font-size: 25px;
  outline: none;
  transition: 0.3s;
  padding-bottom: 0px;
  color: #278cbf;
  text-align: left;
  background-color: #f5f5f5;
}

.middle-div {
  flex-grow: 1; /* Allow the div to grow to fill the remaining space */
}

.line {
  width: 100%;
  height: 5px;
  background-color: white;
}

.line_header{
  transition: font-size 0.3s ease; /* Smooth transition */
}

.middle_header{
  transition: font-size 0.3s ease; 
  font-size: 50px;
}


@keyframes expandLine_left {
  from {
    width: 0;
    margin-left: 100%;
  }
  to {
    width: 100%;
    margin-left: 0;
  }
}

@keyframes expandLine_right {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}


/* Apply the animation when the animate-line class is present */
.animate-line-left {
  animation: expandLine_left 2s forwards; /* Adjust the animation duration as needed */
}


.animate-line-right {
  animation: expandLine_right 2s forwards; /* Adjust the animation duration as needed */
}



  /* Apply row layout for larger screens */
  @media (max-width: 601px) {

    .middle_header {
      font-size: 40px;
    }

  }

  
  /* Apply row layout for larger screens */
  @media (min-width: 601px) {

    .baslik {
      font-size: 40px; /* Adjust font size as needed */
    }
    .middle_header:hover {
      font-size: 52px;
    }

  }
  

  .line_header:hover {
    font-size: 110%;
  }



