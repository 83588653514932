/* timeline.css */


  .frame{
    height: 680px;
  }

  .card_window {
    height: 700px; /* Set a fixed height */
    width: 100%;
    overflow-x: hidden; /* Hide horizontal overflow */
    overflow-y: hidden; /* Hide vertical overflow */
    transform: translateY(-20%);

  }

  
  .year_header{
    transform: translateY(-180px) translateX(-50px);
    height: 80px;
  }

   
  .times{
    position: relative;
    background-color: transparent;
    height: 600px;
    transition: transform 0.3s ease; /* Add transition for smooth movement */
    transform: translateY(20%);
    width: 100%;
    margin-bottom: 100px;

  }

  .time_slide_card {
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out, width 0.5s ease-in-out; /* Add transition for smooth movement and width enlargement */
    width: 50%;
    height: 100%;
    
  }


  .times_side{
    position: relative;
    background-color: transparent;
    height: 600px;
    transition: transform 0.3s ease; /* Add transition for smooth movement */
    transform: translateY(20%);
  }

  .year {
    background-color: transparent;
    position: absolute;
    top: 15%;
    left: 9%;

  }
  
  .year_0{
    background-color: transparent;
    position: absolute;
    top: 0%;
    left: 0%;
  }


  .timer_button {
    width: 350px;
    border-radius: 50px 0 50px 0 ;
    transition: width 0.3s ease; /* Add transition property */
    background-color: #7a7a946b;
    backdrop-filter: blur(3px); /* Blur effect with 10px radius */
    /* Add any other styles you need for your button */
    transform: translateX(100px);
  }

  .timeline_label{
    width: 100%;
  }


  .timer_headers {
    width: 100%;
  }
  

  .timeline_header {
    color: #278cbf;
    font-size: 40px;
 
  }

  .timeline_text {
    color: #ffffff;
    font-size: 18px;
    text-align: left ;
 
  }

  .timeline_year {
    color: #404040;
    font-size: 200px;
    font-weight: bold;
    transition: transform 1s ease, width 1s ease, font-size 1s ease, color 1s ease; /* Add transitions for smooth movement, width enlargement, font size change, and color change */
    text-align: start;
    height: 500px;
    width: 500px;
    font-family: 'Montserrat-Bold', sans-serif;
    

 
  }

  .timeline_year0 {
    background: linear-gradient(317.85deg, #278cbf -1.59%, #278cbf61 100%);
    -webkit-background-clip: text; /* For WebKit/Blink browsers */
    background-clip: text;
    color: transparent; /* Make the text transparent */
    font-size: 350px;
    font-weight: bold;
    text-align: end;
    transition: transform 1s ease, width 1s ease, font-size 1s ease, color 1s ease;
    pointer-events: none; 
    height: 500px;
    width: 500px;
    font-family: 'Montserrat-Bold', sans-serif;


  }
  

  .timeline_year0.fade-out {
    opacity: 0;
  }
  
  .timeline_year0.fade-in {
    opacity: 1;
  }
  
  .arrow{
    display: none;
    transition: font-size 0.3s ease; /* Add transition for smooth font size change */
  }

  @media (min-width: 601px) {

    

  .baslik:hover .year_header .timeline_year0 {
    transform: translateY(-0px) translateX(0%);

  }
  
  .times:hover > .year >.timeline_year {
    background: linear-gradient(317.85deg, #278cbf -41.59%, rgba(250, 0, 0, 0) 143.46%);
    -webkit-background-clip: text; /* For WebKit/Blink browsers */
    background-clip: text;
    color: transparent; /* Make the text transparent */
    font-size: 350px;
    transform: translateY(-325px) translateX(360px);
  }

  .timer_button:hover  {
    width: 650px;

  }
  
  }
  

  .times:hover .arrow {
    display: block; /* Hide the .downarrow when .times is being hovered */
  }
  

  .arrow:hover {
    font-size: larger; /* Increase width on hover */
  }
  
  .full-width {
    width: 100%;
  }

  @media (max-width: 601px) {

    .frame{
      height: 600px;
    }

    .time_slide_card {
      width: 70%;
      height: 100%;
      
    }

  .timer_button {
    width: 260px;
    height: 400px;
  }

  .timeline_year0{
    font-size: 120px;
    transform: translateY(90px) translateX(30px);
    width: 200px;
  }


  .timeline_year{
    transform: translateY(-110px) translateX(20px);
    font-size: 100px;
    width: 200px;
    height: 200px;
  }


    .arrow {
      display: block; /* Hide the .downarrow when .times is being hovered */
      color: #278cbf !important;
    }
    
    .full-width {
      
    }


    /*  YUKARIDA AŞAĞIDAKİ ÖZELLİKLER ÇOKTAN GERÇEKLEŞİYOR */
  

  }

