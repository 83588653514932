.label {
    font-size: 55px;
    outline: none;
    transition: 0.3s;
    padding-bottom: 0px;
    color: #0635c1;
    text-align: left;
}

.video-1-container{
  background-color: #f5f5f5 !important;
}

.video_page_header {
  transition: font-size 0.3s;
}

.video_page_header:hover {
  font-size: 65px;
}



.buttontr {
  background: transparent;
  border: none;
  margin: 0 auto 0;
  display: block;
  font-size: 50%;
  outline: none;
  position: relative;
  transition: 0.3s;
  padding-bottom: 0px;
  color: #00c2e7;
}

.menu_button {
  transition: background-color 0.2s; /* Transition the background property over 0.2s */
}


.menu_button:hover {
  background-color: rgba(128, 128, 128, 0.212);
  border-radius: 10px;
}


.buttontr:hover {
  background-color: rgba(128, 128, 128, 0.212);
  font-size: 52%;
  border-radius: 10px;
  padding: 0;
}

.buttontr:active {
  background: rgb(33, 37, 41);
  color: #00c2e7;
  transition: none;
}


.video_frame {
  border: 70px solid transparent;
  border-radius: 5px;
}

.bayrak{
  width: 50%;
}

.left_bottom_big{
  transform: translateY(5.5%);
  font-size: 70px;
  transition: font-size 0.3s;
}

.left_bottom_small{
  font-size: 20px;
  transition: font-size 0.3s;
}


.left_bottom_big:hover {
  font-size: 75px;
}

.left_bottom_small:hover {
  font-size: 25px;
}

@media (max-width: 600px) { /* Adjust font size for screens smaller than 576px (e.g., phones) */
  .label {
    font-size: 30px; /* Adjust font size as needed */
  }

  .button {
    font-size: 30px;
  }

  .left_bottom_big{
    transform: translateY(5%);
    font-size: 52px;
  }

  .left_bottom_small{
    font-size: 15px;
  }

  .video_page_header:hover {
    font-size: 40px;
  }
  

  .left_bottom_big:hover {
    font-size: 55px;
  }
  
  .left_bottom_small:hover {
    font-size: 17px;
  }

}


.button:before {
    content: '';
    border-bottom: 3px solid #278cbf;
    padding: 0 25px;
    position: absolute;
    bottom: 0;
    left: 10;
    right: 0;
    margin: 0 auto;
    width: 0;
    transition: 0.3s;
}

.button:hover:before {
    width: 100%;
    padding: 0;
}

.button:active {
    background: black;
    color: #00c2e7;
    transition: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent; /* Semi-transparent black overlay */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition */
  }
  
  .btn:hover .overlay {
    opacity: 1; /* Show overlay on hover */
  }
  



/* Define animation classes for sliding up and down */
.slideUp {
  animation: slideUpAnimation 0.3s ease-in forwards;
}

.slideDown {
  animation: slideDownAnimation 0.3s ease-out forwards;
}

/* Define keyframes for slide up animation */
@keyframes slideUpAnimation {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
}

/* Define keyframes for slide down animation */
@keyframes slideDownAnimation {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0%);
  }
}








/* Apply row layout for larger screens */
@media (max-width: 601px) {

  .baslik_subnavbar {
    font-size: 50px; /* Adjust font size as needed */
  }


}


.label-animation {
  animation: label-animation 0.5s ease-in-out;
}

@keyframes label-animation {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

