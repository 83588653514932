
.video-container{
  height: 500px;
  scale: 1.1;
  overflow: hidden;
  position: relative;
}

.kart_1 {
    height: 350px;
    transition: all .3s;
  }

.kart_2 {
    height: 420px;
    border-radius: 30px 30px 0 0; /* Top-left and top-right corners have radius, bottom corners have no radius */
    padding: 0.2%;
    transition: all .3s;

    }

.kart_3 {
  height: 350px;
  transition: all .3s;
    }


.footer_title{
    font-size: 28px;
    transition: all .3s;
    font-family: 'Power Grotesk', sans-serif;

}

.footer_deatil_text{
  font-size: 15px;
  font-family: 'Gotham', sans-serif; 
}



.detay_title {
  font-size: 30px;
  width: 250px;
  text-align: center;
  text-decoration: underline; /* Underline the text */
  transition: all .3s;
  
}

.hidden_bottom{
  display: none !important;
}

.redline_footer_left {
    padding: 4%;
    border: 2px solid transparent; /* To make sure the outline doesn't push the content */
    box-shadow: 0 0 20px #278cbfa1; /* Glowing effect */
    border-radius: 30px 0 0 30px  ; /* Adjust the value as needed for the desired roundness */

  }

  .redline_footer_right {
    padding: 4%;
    border: 2px solid transparent; /* To make sure the outline doesn't push the content */
    box-shadow: 0 0 20px #278cbfa1;/* Glowing effect */
    border-radius: 0 30px 30px 0  ; /* Adjust the value as needed for the desired roundness */

  }
  

  .detay_button_footer {
    width: 100%;
    background-color:  #278cbf;
    margin-bottom: 5px;
    height: 60px;
    font-size: 25px;
    font-family: 'Power Grotesk', sans-serif;
    color: white;
  }
  

  .footer_h1{
    font-size: 70%;
    transition: all .3s;

  }

  .footer_h2{
    font-size: 70%;
    display: block;
    transition: all .3s;
  }

  .footer_list{
    display: block;
  }

  ul {
    font-size: 40%;
    list-style-type: circle; /* or 'circle' or 'square' */
    margin-left: 15%;
  }


  p {
    text-align: left;
  }

  .yaprak{
    margin-top: 50%;
    height: 130px;
}

  .adres, .iletisim {
    width: 70px;;
  }

  li{
    transition: .3s;
  }




  @keyframes rotate {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  
  .rotating {
    animation: rotate 2s linear infinite; /* Adjust duration and timing function as needed */
  }
  

  @media (min-width: 601px) {

  .kart_1:hover{
    height: 310px;
  }
  
  .kart_3:hover{
    height: 310px;
  }
  
  
  .footer_title:hover{
    font-size: 50%;
  }
  
  .detay_title:hover{
    font-size: 70%;
  }

  .footer_h1:hover{
    font-size: 80%;
  }

  .footer_h2:hover{
    font-size: 80%;
  }

  li:hover{
    font-size: 105%;
  }


  .footer_title_middle{
    font-size: 16px;
  }

  }

  .whatsapp_button_container {
    position: absolute;
    bottom: 90px;
    right: 30px;
    z-index: 1000;
    width: 120px;
    height: 120px;
  }


  @media (max-width: 601px) {

    .hidden_bottom{
      display: flex !important;
    }


    .video-container{
      height: 220px;
      scale: 1;
    }

    .altlogo{
        height: 75px;
    }

    .kart_1 {
        height: 150px;
      }
    
    .kart_2 {
        height: 70%;
        padding: 1%;
        width: 90%;

    
        }
    
    .kart_3 {
        height: 150px;
        }

    .footer_title{
      font-size: 24px;
    }


    .footer_h1{
      font-size: 40px;
    }

    .footer_deatil_text{
      font-size: 12px;
    }

    .detay_title{
        font-size: 24px;
        width: 100%;
    }

    .yaprak{
        height: 60px;
    }

    .adres, .iletisim {
        width: 30px;
      }

      .detay_button_footer{
        height: 60px;
        margin-bottom: 0px;
        font-size: 20px;
      }

      .redline_footer {
        border-radius: 40px; /* Adjust the value as needed for the desired roundness */
    
      }

      .footer_h2{
        font-size: 35px;
        height: 60px;
        display: none;
      }

      .footer_list{
        display: none;
      }

      p {
        text-align: center;
      }

      .footer_title_middle{
        font-size: 18px;
      }
      
      .whatsapp_button_container {
        position: absolute;
        bottom: 250px;
        right: 10px;
        z-index: 1000;
        width: 120px;
        height: 120px;
      }


  }

  .logo_container{
    overflow: hidden;
  }

  .grow-animation {
    transform: scale(0); /* Start from a dot */
    animation: growAndRotate 4s forwards; /* Grow to maximum size and rotate around y-axis */
  }
  
  @keyframes growAndRotate {
    0% {
      transform: scale(0) rotateY(-360deg); /* Start from a dot with no rotation around y-axis */
    }
    100% {
      transform: scale(1) rotateY(360deg); /* Grow to maximum size with a full rotation around y-axis */
    }
  }
  
  
  