@font-face {
  font-family: 'Power Grotesk';
  src: url('./fonts/PowerGrotesk-Regular.ttf') format('truetype');
  /* You might need to adjust the path based on your directory structure */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
      url(./fonts/Montserrat/static/Montserrat-Medium.ttf) format('truetype');
}


@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
      url(./fonts/Montserrat/static/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham Regular'), local('Gotham-Regular'),
      url('fonts/Gotham-Font/Gotham-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham_Bold';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham Regular'), local('Gotham-Regular'),
      url('fonts/Gotham-Font/GothamBold.ttf') format('woff2');
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


