.work_label_1 {
    background-color: transparent;
    font-size: 50px;
    outline: none;
    transition: opacity 0.2s ease-in-out, font-size 0.2s; 
    opacity: 1;
    padding-bottom: 0px;
    color: white;
    text-align: left;

}



.work_label_1.fade-out {
    opacity: 0;
}


.work_label_2 {
    background-color: transparent;
    font-size: 15px;
    outline: none;
    transition: opacity 0.2s ease-in-out, font-size 0.2s; 
    padding-bottom: 0px;
    color: white;
    text-align: left;
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    font-family: 'Gotham', sans-serif; /* Use Gotham font */
}




.work_label_2.fade-out {
    opacity: 0;
}

.work_button {
    width: 300px;
    height: 80px;
    background-color: transparent;
    border: 2px solid #FFB0B0;
    cursor: pointer;
    transition: all 0.3s ease; /* Add transition for smooth animation */
    }





@media (min-width: 601px) {

    .work_label_1:hover {
        font-size: 52px;
    }
    .work_label_2:hover {
        font-size: 17px;
    }

    .work_button:hover {
        width: 320px;
        height: 80px;
        }

}


@media (max-width: 601px) {

    .work_label_1 {
        font-size: 40px;

        
    
    
    }
}