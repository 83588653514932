.container {
    background-color: transparent;
    width: 100%;
}

.centered_image_uretim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-10%, -170%) translateX(-10px);
  width: 180px;
  height: 180px;
  background-size: cover;
  opacity: 1;
}

.pager_label_1 {
    background-color: transparent;
    font-size: 34px;
    outline: none;
    transition: opacity 0.2s ease-in-out, font-size 0.2s; 
    opacity: 1;
    padding-bottom: 0px;
    color: #64647B;
    text-align: left;

}

.pager_label_1:hover {
  font-size: 95%;
  
}

.pager_label_1.fade-out {
  opacity: 0;
}


.pager_label_2 {
    background-color: transparent;
    font-size: 15px;
    outline: none;
    transition: opacity 0.2s ease-in-out, font-size 0.2s; 
    padding-bottom: 0px;
    color: #64647B;
    text-align: left;
    font-family: 'Gotham', sans-serif;  

}

.pager_label_2:hover {
  font-size: 55%;
}

.pager_label_2.fade-out {
  opacity: 0;
}

.previous_button, .next_button {
    width: 65px;
    height: 65px;
    background-color: #278cbf;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease; /* Add transition for smooth animation */
  }
  
  .previous_button::before, .next_button::after {
    content: "\2190"; /* Unicode for left arrow */
    font-size: 30px;
  }
  
  .next_button::after {
    content: "\2192"; /* Unicode for right arrow */
  }
  
  /* Additional styles */
  .previous_button:hover, .next_button:hover {
    background-color: #999999de;
    width: 68px;
    height: 68px;
  }
  

  
  /* Reset hover styles when button is clicked */
  .previous_button:active:hover, .next_button:active:hover {
    background-color: #cccccc63; /* Reset background color */
    width: 80px;
    height: 80px;
  }
  

  .slider-container {
    overflow: hidden;
    height: 100%;
  }


  .slide_card {
    display: flex;
    transition: transform 0.5s ease-in-out;    
    width: 1500%;
    align-items: center;
  }
    

  .card_front{
    background-color: rgba(100, 100, 123, 0.5);
 /* Fully opaque light grey background */
    backdrop-filter: blur(3px); /* Blur effect with 10px radius */
    border-radius: 30px 0 30px 0 ; /* Top left and bottom right corners have border radius */
    margin-right: 60px;
    margin-left: 20px;
    width: 370px;
    transition: height 0.3s ease; /* Add transition for height property */
  }


  .title_window{
    width: 100%;
  }

  .picture_window{
    width: 100%;
  }

  .pager_card_header_1{
    background-color: transparent; /* Transparent background */
    color: white;
    font-size: 80px;
    transition: font-size 0.2s;
    font-family: 'Montserrat', sans-serif;
  }


  .pager_card_header_2 {
    background-color: transparent; /* Transparent background */
    color: white;
    font-size: 50px;
    display: flex;
    justify-content: center; /* Align horizontally center */
    align-items: center; /* Align vertically center */
    transition: font-size 0.2s;
  }

  .pager_card_header_1:hover{
    font-size: 85px;
  }
  
  .pager_card_header_2:hover{
    font-size: 55px;
  }

  .image_container {
    border-radius: 20px; /* Adjust the value as needed */
    width: 270px;
  }
  
  .right_grey{
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 30px 0 0 0 ;
    transform: translate(480px, -275px);
    width: 60px;
    height: 550px;
    background-size: cover;
    background-color: rgb(94, 90, 90)

  }

  @media (min-width: 601px) {

    
    .slide_card_wrapper{
      height: 100%;
      width: 100%;
    }
    

    .label_1{
      font-size: 80px;
    }

    .label_2{
      font-size: 40px;
    }


    .card_header_1{
      font-size: 80px;
    }

    .card_header_2{
      font-size: 50px;
    }

  }

  @media (max-width: 600px) {

  .pager_back {
    background-image: none !important; 
  }

  .card_front{
    margin-right: 20px;
    margin-left: 0px;
    width: 280px;
  
  }

  .title_window{
    width: 100%;
  }

  .pager_card_header_1{
    font-size: 60px;

  }

  .pager_card_header_2 {
    font-size: 35px;

  }

  .pager_card_header_1:hover{
    font-size: 62px;
  }
  
  .pager_card_header_2:hover{
    font-size: 37px;
  }

  .picture_window{
    width: 100%;
  }
    
  .image_container {
    border-radius: 20px; /* Adjust the value as needed */
    width: 200px;
  }

  .centered_image_uretim {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-130%, -50%) translateX(-10px);
    width: 120px;
    height: 120px;
    background-size: cover;
    opacity: 0.5;
  }


}