.segment_button {
    width: 300px;
    height: 80px;
    background-color: transparent;
    border: 2px solid #278cbf;
    cursor: pointer;
    transition: all 0.3s ease; /* Add transition for smooth animation */
    }






.image_container_way {
    border-radius: 20px 0 20px 0; /* Adjust the value as needed */
    box-shadow: 0px 0px 70px 0px #278cbf; /* Adjust the shadow values as needed */
    padding: 0;
    margin: 0;
    }


.form_label_1 {
    background-color: transparent;
    font-size: 52px;
    outline: none;
    transition: opacity 0.2s ease-in-out, font-size 0.2s; 
    opacity: 1;
    padding-bottom: 0px;
    color:  #278cbf;
    text-align: left;
    


}


.form_label_1.fade-out {
    opacity: 0;
}


.form_label_2 {
    background-color: transparent;
    font-size: 15px;
    outline: none;
    transition: opacity 0.2s ease-in-out, font-size 0.2s; 
    padding-bottom: 0px;
    color:  #278cbf;
    text-align: left;
    font-family: 'Gotham', sans-serif; /* Use Gotham font */

}


.form_label_2.fade-out {
    opacity: 0;
}

@media (min-width: 601px) {

    .form_label_1:hover {
        font-size: 54px;
    }

    .form_label_2:hover {
        font-size: 17px;
    }

    .segment_button:hover {
        width: 320px;
        height: 80px;

        }
}


@media (max-width: 601px) {

    .form_label_1 {
        font-size: 40px;

        
    
    
    }

    .image_container_way {
        height: 300px;

    }
}