.video_text{
    font-size: 17px;
    text-align: center;
    font-family: 'Gotham', sans-serif; /* Use Gotham font */

}

.perakende_header{
    font-family: 'Montserrat', sans-serif; /* Use Gotham font */
}

@media (max-width: 601px) {

    .video_text{
        font-size: 15px;
    }

}
