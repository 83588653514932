  
.baslik_subnavbar {
    font-size: 70px;
    outline: none;
    color: #278cbf;
    text-align: left;
    transition: opacity 0.3s ease-in-out; /* Apply the transition effect */
    
  }
  
  
  .fade-in {
    transition: opacity 0.3s ease-in-out; /* Apply the transition effect */
  }
  
  .fade-out {
    opacity: 0;
  }
  
  .middle-div {
    flex-grow: 1; /* Allow the div to grow to fill the remaining space */
  }
  
  
  .line {
    width: 100%;
    height: 1px;
    background-color: #404040;;
  }

.subheader{
    transition: font-size .3s;
  }
  .subheader:hover{
    font-size: 110%;
  }



  .middle-div {
    flex-grow: 1; /* Allow the div to grow to fill the remaining space */
  }
  
  .line {
    width: 100%;
    height: 5px;
    background-color: #404040;;
  }
  
  .line_header{
    transition: font-size 0.3s ease; /* Smooth transition */
  }
  
  @keyframes expandLine_left {
    from {
      width: 0;
      margin-left: 100%;
    }
    to {
      width: 100%;
      margin-left: 0;
    }
  }
  
  @keyframes expandLine_right {
    from {
      width: 0px;
    }
    to {
      width: 100%;
    }
  }
  
  
  /* Apply the animation when the animate-line class is present */
  .animate-line-left {
    animation: expandLine_left 2s forwards; /* Adjust the animation duration as needed */
  }
  
  
  .animate-line-right {
    animation: expandLine_right 2s forwards; /* Adjust the animation duration as needed */
  }
  

    /* Apply row layout for larger screens */
    @media (max-width: 601px) {

        .subheader {
          font-size: 40px; /* Adjust font size as needed */
        }
    
      }