
.carousel {
  position: relative;
  width: 100%;
  overflow-x: hidden; /* Hide horizontal overflow */
  overflow-y: hidden; /* Hide vertical overflow */
  
}

.container {
  display: flex;
  flex-direction: column; /* Adjusted to column layout by default */
  padding: 0%;
  margin: 0%;
  
  
}

.blocks{
  background-color:white;
  transform: translateY(-1%);
}

/* Hide scrollbar */
.carousel::-webkit-scrollbar {
  display: none;
}

.scroll-button {
  display: none; /* Hide scroll buttons */
}



.redline {
  padding: 10px;
  border: 2px solid transparent; /* To make sure the outline doesn't push the content */
  outline: 2px solid #278cbf68; /* Red outline */
  box-shadow: 0 0 50px #278cbf;
  border-radius: 20px; /* Adjust the value as needed for the desired roundness */
}

.bar {
width: 100%;
background-color: transparent;
box-shadow: 0 0 50px #278cbf6e; /* Glowing effect */
border-radius: 20px; /* Adjust the value as needed for the desired roundness */
}



.sektor_button {
  opacity: 1;
  height: 140px;
  transition: width 0.3s ease; /* Add transition property */
  

  /* Add any other styles you need for your button */
}

.vertical-text label {
  text-align: left;
}

.card_headers {
  width: 400px; /* Without quotes */
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.sektor_label {
  color: #212121;
  text-align: left;
  font-size: 80%;
}

.label_frame{
  width: 250px;
}

.button_header{
  height: 100%;
  width: 100%; /* Display the "sector_card" div on hover */
}

.sektor_button:hover .card_headers{
  flex-direction: row;
  padding-left: 30px;
  padding-bottom: 20px;
}


.picture_1{
  height: 100%;
}


.image {
  width: 100%;
}

.image_b {
  display: none;
  width: 50px;
}

.sektor_button:hover .image_b {
  display: block;
}

.sektor_button:hover .image {
  display: none;
}

.sektor_button:hover .label_frame{
  width: 110px;
}


/* Apply row layout for larger screens */
@media (min-width: 601px) {
  .container {
    flex-direction: row;

  }

  .sektor_button{
    opacity: 1;
    width: 155px;
    height: 400px;
  }

  .sektor_button:hover {

    background-color: #f5f5f5; /* Change to any color you desire */
    width: 300px;
    background-image: none !important; /* Remove background image */
  }

  #last_sektor_button{
    transition: all 0.3s;

  }

  #last_sektor_button:hover{
    transform: translateX(-110px); /* Move .blocks 150px to the left when hovered */

  }
  
  .vertical-text {
    transform: rotate(270deg) translateY(-170%) translateX(50%); /* Rotate the text 270 degrees clockwise and lift it up */
    transform-origin: bottom; /* Set the origin to the center */
  }
  
  .sektor_button:hover .vertical-text {
    transform: none; /* Reset the rotation and lift on hover */
  }

  
  .sektor_button:hover .sector_card_2 {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
  }

  .right_symbol{
    width: 35%;
    text-align: left;
  }
  .number_label{
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
  }

  .sektor_button:hover .number_label {
    justify-content: start; /* Horizontally center the content */
    margin-left: 5%;

  }

  .card_video {
    height: 100%;
  }

}


@media (max-width: 601px) {

  .sektor_label {
    color: black;
    text-align: left;
    font-size: 100%;
  }
  
  .button_header{
    height: 100%;
    width: 80%;
  }

  .sektor_button {
    width: 100%;
    transition: height 0.3s ease; /* Add transition property */
  }

  .sektor_button:hover {
    background-color: #f5f5f5; /* Change to any color you desire */
    height: 350px;
    background-image: none !important; /* Remove background image */
  }

  .vertical-text {
    transform: none; /* Reset the rotation and lift on hover */

  }

  
  .sektor_button:hover .vertical-text {
    transform: rotate(90deg) translateY(-60%) translateX(10%); /* Rotate the text 270 degrees clockwise and lift it up */
    transform-origin: left; /* Set the origin to the center */
  }



  .sektor_button:hover .button_header{
    height: 20%; /* Display the "sector_card" div on hover */
  }


  .sektor_button:hover .sector_card_1 {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 240px;
  }

  .right_symbol{
    width: 100%;
    text-align: right;
  }

  .card_headers {
    padding-top: 20px;
    width: 250px; /* Without quotes */
  }

  .sektor_button:hover .card_headers{

    padding-left: 0px;
    padding-bottom: 0px;
  }

  .sektor_button:hover .image_b {
    margin-top: -4px;
  }

  .card_video {
    height: 100%;
  }

}

.sektor_button:hover .sektor_label {
  color: white;
  display: flex;
  align-items: end; /* Vertically center items */
}

.sector_card_1 {
  display: none; /* Hide the "sector_card" div by default */
}

.sector_card_2 {
  display: none; /* Hide the "sector_card" div by default */
}



/* Define animation classes for sliding up and down */
.slideUp {
  animation: slideUpAnimation 0.3s ease-in forwards;
}

.slideDown {
  animation: slideDownAnimation 0.3s ease-out forwards;
}

/* Define keyframes for slide up animation */
@keyframes slideUpAnimation {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
}

/* Define keyframes for slide down animation */
@keyframes slideDownAnimation {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0%);
  }
}
