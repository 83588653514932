.input_field{
  height: 70px;
  margin: 8px;
  border-radius: 15px;
  padding-left: 30px;
  font-size: 18px;
  color: black;
  background-color: white;
  box-shadow: 0 0 10px #278cbf; /* Glowing in #278cbf */

}

.basvuru_title{
    font-size: 18px;
}


.text-field{
    height: 150px;
    margin: 2%;
    border-radius: 15px;
    border: 1px solid #278cbf;
    padding-left: 30px;
    padding-top: 20px;
    font-size: 18px;
    color: black;
    box-shadow: 0 0 10px #278cbf; /* Glowing in #278cbf */


}

.work_button {
    width: 300px;
    height: 80px;
    background-color: transparent;
    border: 2px solid #278cbf;
    cursor: pointer;
    transition: all 0.3s ease; /* Add transition for smooth animation */
    }


.work_button:hover {
    width: 320px;
    height: 80px;
    }

    

.Cv_Button{
    transition: all .3s;
    
}
    
.Cv_Button:hover {
    border-radius: 30%;
    box-shadow: 0 0 10px 10px #278cbf; /* Red border shadow */
    background-color: #278cbf;
}