


.blog-container {
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  height: 100%;
}


.blog_card {
  display: flex;
  transition: transform 0.5s ease-in-out;    
  width: 5500%;
  align-items: center;
}
  

.blog_front{
  
  border-radius: 30px 0 30px 0 ; /* Top left and bottom right corners have border radius */
  height: 100% ;
  width: 1110px;
  transition: height 0.3s ease; /* Add transition for height property */
}

.blog_card_title{
  color: white;
  font-family: 'Power Grotesk', sans-serif;
  font-size: 26px;
}

.blog_card_text{
  color: white;
  font-family: 'Gotham', sans-serif; /* Use Gotham font */
  font-size: 13px;
  height: 400px;
}

.blog_content{
  transform: translate(50px, 0px);
  background-color: rgba(61, 60, 60, 0.5); 
  border-radius: 30px 30px 30px 30px ; /* Top left and bottom right corners /* Fully opaque light grey background */
  backdrop-filter: blur(8px); /* Blur effect with 10px radius */
  /* Add any other styles you need for your button */
  height: 320px;
  width: 320px;
  transition: all .3s;
}

.date_title {
  width: 450px;
  position: absolute;
  z-index: -1;
  transform: translate(-20px, -50px);
}

.day_title{
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 130px;
  font-weight: bold;
  height: 130px;
  transition: all .3s;

}

.month_title{
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 40px;
  font-weight: bold;
  transition: all .3s;
}




.blog_card_header_1{
  background-color: transparent; /* Transparent background */
  color: white;
  font-size: 100%;
  display: flex;
  justify-content: center; /* Align horizontally center */
  align-items: center; /* Align vertically center */
  transition: font-size 0.2s;
  width: 70px;
}





.blog_previous_button, .blog_next_button {
  width: 50px;
  height: 50px;
  background-color: #cccccc63;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease; /* Add transition for smooth animation */
}

.blog_previous_button::before, .blog_next_button::after {
  content: "\2190"; /* Unicode for left arrow */
  font-size: 30px;
}

.blog_next_button::after {
  content: "\2192"; /* Unicode for right arrow */
}

/* Additional styles */
.blog_previous_button:hover, .blog_next_button:hover {
  background-color: #999999de;

}



/* Reset hover styles when button is clicked */
.blog_previous_button:active:hover, .blog_next_button:active:hover {
  background-color: #cccccc63; /* Reset background color */

}

.pagination_button{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease; /* Add transition for smooth animation */
  border: 3px solid grey; /* Add grey border */
}

.pagination_button.active{
  background-color: grey;

}

.pagination_button:hover{
  background-color: rgb(225, 225, 225);
  
}

.button_pack{
  width: 280px;

}

@media (min-width: 601px) {

  .blog_content:hover {
    background-color: rgba(125, 123, 123, 0.5); 
  }


  .blog_card_content:hover > .date_title .day_title {
    font-size: 135px;
  }

  .blog_card_content:hover > .date_title .month_title {
    font-size: 42px;
  }

}

@media (max-width: 601px) {


  .blog_front{
    width: 430px;
    height: 100% ;

  }

  .date_title {
    width: 50px;
    transform: translate(-170px, -40px);
  }
  
  .day_title{
    font-size: 100px;
    height: 100px;
  
  }
  
  .month_title{
    font-size: 40px;
  }
 
  
  .blog_card_content{
    height: 250px;
    width: 100%;
  }



.blog_card_title{
  font-family: 'Power Grotesk', sans-serif;
  font-size: 20px;
}

.blog_card_text{
font-size: 10px;
}

.blog_content{
  transform: translate(50px, 0px);
  background-color: rgba(61, 60, 60, 0.5); 
  border-radius: 30px 30px 30px 30px ; /* Top left and bottom right corners /* Fully opaque light grey background */
  height: 250px;
  width: 250px;
  backdrop-filter: blur(8px); /* Blur effect with 10px radius */

}

.button_pack{
  width: 230px;

}

.blog_previous_button, .blog_next_button, .pagination_button {
  width: 50px;
  height: 50px;

}

}


