


.iletisim_header{
    font-size: 20px;
    
}


.iletisim_label{
    font-size: 13px;
    line-height: 13px;
    font-family: 'Gotham', sans-serif; /* Use Gotham font */

}

.iletisim_input_field{
    height: 70px;
    width: 95%;
    border-radius: 15px;
    padding-left: 30px;
    font-size: 18px;
    color: black;
    background-color: white;
    box-shadow: 0 0 10px #278cbf;; /* Glowing effect */
  }

  
.iletisim_submit_btn{
    height: 60px;
    width: 100%;
    border-radius: 15px;
    font-size: 22px;
    color: #278cbf;
    font-family: 'Montserrat', sans-serif;
    background-color: #00c2e7;
    box-shadow: 0 0 20px #278cbf;; /* Glowing effect */
  }

  .bilgi_input_field{
    height: 170px;
    width: 100%;
    border-radius: 15px;
    padding-left: 30px;
    font-size: 18px;
    color: black;
    background-color: white;
    box-shadow: 0 0 10px #278cbf;; /* Glowing effect */
  }

  .iletisim_work_button {
    width: 300px;
    height: 80px;
    background-color: transparent;
    border: 2px solid #00c2e7;
    cursor: pointer;
    transition: all 0.3s ease; /* Add transition for smooth animation */
    }




.iletisim_work_button:hover {
    width: 320px;
    height: 80px;
    }

  .iletisim_map{
    border: 0;
    border-radius: 30px 0 30px 0;
    width: 100%;
    height: 405px;
  }

  @media (max-width: 601px) {


      .iletisim_submit_btn{
        height: 50px; 
      }

      

  }
