


.big_slider_label_1 {
    background-color: transparent;
    font-size: 50px;
    outline: none;
    transition: opacity 0.2s ease-in-out, font-size 0.2s; 
    opacity: 1;
    padding-bottom: 0px;
    color: #64647B;
    text-align: left;

}

.big_slider_label_1:hover {
  font-size: 52px;
}

.big_slider_label_1.fade-out {
  opacity: 0;
}




.big_slider_label_2 {
    background-color: transparent;
    font-size: 15px;
    outline: none;
    transition: opacity 0.2s ease-in-out, font-size 0.2s; 
    padding-bottom: 0px;
    color: #64647B;
    text-align: left;
    font-family: 'Gotham', sans-serif; /* Use Gotham font */


}

.big_slider_label_2:hover {
  font-size: 17px;
}

.big_slider_label_2.fade-out {
  opacity: 0;
}

.big_slider_previous_button, .big_slider_next_button {
    width: 65px;
    height: 65px;
    background-color: #00c2e7;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease; /* Add transition for smooth animation */
  }
  
  .big_slider_previous_button::before, .big_slider_next_button::after {
    content: "\2190"; /* Unicode for left arrow */
    font-size: 30px;
  }
  
  .big_slider_next_button::after {
    content: "\2192"; /* Unicode for right arrow */
  }
  
  /* Additional styles */
  .big_slider_previous_button:hover, .big_slider_next_button:hover {
    background-color: #999999de;
    width: 68px;
    height: 68px;
  }
  

  
  /* Reset hover styles when button is clicked */
  .big_slider_previous_button:active:hover, .big_slider_next_button:active:hover {
    background-color: #cccccc63; /* Reset background color */
    width: 80px;
    height: 80px;
  }
  

  .big_slider_slider_container {
    overflow: hidden;
    height: 100%;
  }


  .big_slider_slide_card {
    display: flex;
    transition: transform 0.5s ease-in-out;    
    width: 1500%;
    align-items: center;

  }
    

  .big_slider_card_front{
    background-color: rgba(100, 100, 123, 0.5);
 /* Fully opaque light grey background */
    backdrop-filter: blur(3px); /* Blur effect with 10px radius */
    border-radius: 30px 0 30px 0 ; /* Top left and bottom right corners have border radius */
    margin-right: 30px;
    margin-left: 20px;
    transition: height 0.3s ease; /* Add transition for height property */
  }


  .title_window{
    width: 100%;
  }


  .big_slider_card_header_1{
    background-color: transparent; /* Transparent background */
    color: white;
    font-size: 100%;
    display: flex;
    justify-content: center; /* Align horizontally center */
    align-items: center; /* Align vertically center */
    transition: font-size 0.2s;
    width: 70px;
  }

  .big_slider_card_header_1:hover{
    font-size: 105%;
  }

  .big_slider_card_header_2 {
    background-color: transparent; /* Transparent background */
    color: white;
    font-size: 70%;
    display: flex;
    justify-content: start; /* Align horizontally center */
    align-items: center; /* Align vertically center */
    transition: font-size 0.2s;
  }
  
  .big_slider_card_header_2:hover{
    font-size: 100%;
  }

  .image_container_makina {
    border-radius: 20px 0 20px 0; /* Adjust the value as needed */
    width: 85%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1); /* Adjust the shadow values as needed */
  }
  
  .right_grey{
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 30px 0 0 0 ;
    transform: translate(480px, -275px);
    width: 60px;
    height: 550px;
    background-size: cover;
    background-color: rgb(94, 90, 90)

  }

  @media (min-width: 601px) {

    
    .big_slider_slide_card_wrapper{
      height: 100%;
      width: 100%;
    }
    

    .label_1{
      font-size: 80px;
    }

    .label_2{
      font-size: 40px;
    }


    .card_header_1{
      font-size: 80px;
    }

    .card_header_2{
      font-size: 50px;
    }

  }

  @media (max-width: 600px) {



  .big_slider_card_front{
    margin-right: 20px;
    margin-left: 0px;
    width: 280px;
  
  }

  .title_window{
    width: 100%;
  }




}