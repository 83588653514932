

.right_box{
    background-color: rgb(53, 52, 52);
    height: 600px;
    border-radius: 50px 0px 50px 0px ;
    padding-left: 50px;
    color: white;

    
}

.left_box {
    background-color: #f5f5f5  ;
    height: 600px;
    position: relative;
}

@media (min-width: 601px) {
.left_box::before,
.left_box::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: white;
}

.left_box::before {
    top: 0;
    right: -40px;
    border-top: 1px solid white;
}

.left_box::after {
    bottom: 0;
    border-bottom: 1px solid white;
}


.left_box{
    height: 600px;

}

}

.kutu_ozellik{
    width: 420px;
    padding-right: 40px;
}

.header_box{
    width: 100%;
}

.Teknik_Header{
    font-family: 'Montserrat', sans-serif; /* Use Gotham font */
    font-weight: bold;
    font-size: 30px;
    width: 200px;

}

.Teknik_List_1st{
    font-family: 'Gotham', sans-serif; /* Use Gotham font */
    font-size: 15px;
}

.Teknik_List_2nd{
    font-size: 15px;
}

.list_line{
    border-bottom: 1px solid white;
}

.acik_kutu{
    position: absolute;
    transform: translateY(-0%) translateX(21%);
    
}

.fefco_download{
    width: 200px;
    height: 100%;
    border-radius: 0%;
    overflow: hidden;
}

.teknik_simge{
    width: 40px;
}

.simge_text{
    font-size: 17px;
}


@media (max-width: 601px) {

    .acik_kutu{
        width: 75%;
        transform: translateY(100%) translateX(-10%);
        
    }


    .Teknik_Header{
        transform: translateX(70%);
    }

    .fefco_download{
        width: 100px;
        border-radius: 50%;
        overflow: hidden;
        transform: translateY(-110%) translateX(10%);
    }

    .left_box {
        height: 500px;
        position: relative;
    }

}