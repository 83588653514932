.header_1{
    height: 200px;
}

.each_header{
    width: 230px;
    transition: all .3s;
}

.kapasite_label{
    width: 100%;
    text-align: center;
    font-size: 30px;
    padding-bottom: 20px;
    transition: all .3s;
}

.kapasite_band {
    height: 75px;
    font-size: 30px;
    width: 105%;
    border-radius: 40px 0px 40px 0px; 
    text-align: center;
    line-height: 75px; /* Vertically center the text */
    background: #202020;
    border-color: #278cbf;
    color: #278cbf;
    border-width: 2px;
    transition: all .3s;
    

  }

.kapasite_label:hover{
    font-size: 32px;
}

.kapasite_band:hover{
    font-size: 32px;
}

.each_header:hover{
    width: 235px;
}
  

  @media (max-width: 600px) { /* Adjust font size for screens smaller than 576px (e.g., phones) */
    .header_1{
        height: 200px;
    }
    
    .each_header{
        width: 230px;
    }
    
    .kapasite_label{
        width: 100%;
        text-align: center;
        font-size: 15px;
        padding-bottom: 20px;
    }
    
    .kapasite_band {
        height: 75px;
        font-size: 15px;
        width: 105%;
        border-radius: 40px 0px 40px 0px; 
        text-align: center;
        line-height: 75px; /* Vertically center the text */
        background: #278cbf;
        border-width: 2px;
        
    
      }

      .kapasite_label:hover{
        font-size: 17px;
    }
    
    .kapasite_band:hover{
        font-size: 17px;
    }
      
  
  }